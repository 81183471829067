@import '../../theme/variables';

.projectContainer {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 20px;
  justify-content: center;
  margin: 0 auto;

  .subHead {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
    color: $buttonColor;
  }

  .tool {
    text-align: center;
    width: 100%;

    .toolimg {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 30px auto;
      img {
        margin: 10px 15px;
        width: 60px;
        height: 60px;
      }
    }
  }

  .goals {
    font-weight: bold;
    font-size: 18px;
    color: $buttonColor;
    margin-top: 20px;
  }

  .paragraph {
    margin: 10px auto;
  }

  .role {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $buttonColor;
    font-size: 20px;
    font-weight: bold;

    p {
      color: #000;
    }
  }

  .bottomSpace {
    margin-bottom: 30px;
  }

  .image {
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
    }
  }
}
