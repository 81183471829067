$buttonColor: #A24EC9 ;
$buttonHover: #650381 ;
$white: #FFF;
$lightgrey: #f8f8f8 ;

button {
  background-color: $buttonColor;
  border-radius: 100px;
  width: 102px;
  padding: 10px;
  text-align: center;
  color: $white ;
  border: none;

  &:hover {
    background-color: $buttonHover;
  }
}

%projectTitle {
  font-size: 14px;
  font-weight: bold;
}
